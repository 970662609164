const promiseCache = new Map<string, Promise<unknown>>();

/**
 * Deduplicates promises by key. If a promise with the same key is already
 * pending, the existing promise will be returned instead of creating a new
 * promise. After the promise settles, the key will be removed from the cache.
 * This is useful for deduplicating concurrent network requests.
 */
export function withDeduplication<T>(
  key: string,
  callback: () => Promise<T>
): Promise<T> {
  let promise = promiseCache.get(key);
  if (!promise) {
    promise = callback();
    promiseCache.set(key, promise);
    promise.then(
      () => promiseCache.delete(key),
      () => promiseCache.delete(key)
    );
  }
  return promise as Promise<T>;
}

import type {
  Cart,
  CartLineItem,
  AddLineItemsResponse,
  LineItemInput,
  UpdateCartInput,
  UpdateLineItemInput,
} from '@/cart/types';

/**
 * The root route for the cart API.
 * This is used to construct the full URL for the cart API endpoints.
 * By default, uses the root route '/'. This can be overridden in function calls.
 */
const placeholderRooteRoute = '/';

/**
 * Adds line items to the cart.
 * In addition to quantity and variant ID, line items can also include selling plans
 * and custom properties.
 */
export async function addLineItemsHandler(
  items: LineItemInput[],
  rootRoute = placeholderRooteRoute
): Promise<CartLineItem[]> {
  const data = (await post(`${rootRoute}cart/add.js`, {
    items,
  })) as AddLineItemsResponse;
  return data.items;
}

/**
 * Clears the cart.
 * All line items are removed, but cart note and attributes are preserved.
 */
export function clearCartHandler(
  rootRoute = placeholderRooteRoute
): Promise<Cart> {
  return post(`${rootRoute}cart/clear.js`) as Promise<Cart>;
}

/**
 * Gets the current cart.
 */
export function getCartHandler(
  rootRoute = placeholderRooteRoute
): Promise<Cart> {
  return request(`${rootRoute}cart.js`) as Promise<Cart>;
}

/**
 * Updates the cart.
 * This is particularly useful for editing cart note and attributes.
 * It can also be used to perform batch quantity updates on all line items with a given variant id.
 * To update individual line item, use `updateLineItem` instead.
 * @see updateLineItem
 */
export function updateCartHandler(
  input: UpdateCartInput,
  rootRoute = placeholderRooteRoute
): Promise<Cart> {
  return post(`${rootRoute}cart/update.js`, input) as Promise<Cart>;
}

/**
 * Updates a line item in the cart.
 * The `quantity`, `properties`, and `selling_plan` fields may be updated.
 * Updating a line item with a quantity of zero will remove it from the cart.
 * The line item is identified by line number -- its 1-indexed position in the cart items list.
 * Alternatively, items may be identified by variant id.
 * This will update all line items with the given variant id.
 */
export function updateLineItemHandler(
  input: UpdateLineItemInput,
  rootRoute = placeholderRooteRoute
): Promise<Cart> {
  return post(`${rootRoute}cart/change.js`, input) as Promise<Cart>;
}

/**
 * Adds a discount code to the cart. This will wipe out any existing discount codes.
 */
export async function applyDiscountCodeHandler(code: string, rootRoute = placeholderRooteRoute) {
	await request(`${rootRoute}discount/${code}`);
}

function post(path: string, data?: Record<string, unknown>): Promise<unknown> {
  return request(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
}

async function request(path: string, options?: RequestInit): Promise<unknown> {
  const response = await fetch(path, options ?? {});

  if (!response.ok) {
    throw new Error(`${response.statusText} ${await response.text()}`);
  }

  return response.json();
}
